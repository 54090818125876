.main_layout {
    background-color: #fff;    

    .main_header {
        height: auto;
        background-color: #fff;
        border-bottom: #175fa4 solid 1px;
        padding: 0 10px !important;

        .menu_button {
            display: inline;
            margin: 0 10px;            
            @media (min-width: 992px) {
                display: none;
            }
        }

        .logo__container {
            @media (max-width: 991px) {
                display: inline;
            }            
            @media (min-width: 992px) {
                float: left;
            }
            .logo__icon {
                margin: 10px 0;
                width: 160px;
                height: 66px;                
            }
        } 

        .header_menu {
            @media (max-width: 991px) {
                display: none;
            }

            .header_menu_item {
                margin: 0 10px;
            }
        }

        .company_address {            
            line-height: 20px !important;
            margin: 10px 10px 0;

            @media (max-width: 1063px) {
                display: none;
            }
            @media (min-width: 1064px) {
                float: left;
            }
        }

        .company_phones {
            line-height: 20px !important;
            margin: 10px 10px 0;

            @media (max-width: 991px) {
                display: none;
            }
            @media (min-width: 992px) {
                float: left;
            }
        }

        .login_button_group {
            float: right;
            line-height: 30px !important;
            .login_button {
                margin: 20px 0 0;
            }
            .logout_button {
                margin: -10px 0 5px;
            }
            .personal_cabinet_button {
                display: block;
                margin: -10px 0 0;
            }

        }
        
    }

    .main_footer {

        .logo__container {
            
            .logo__icon {
                margin: 10px 0;
                width: 100%;
                
                @media (min-width: 1200px) {
                    max-width: 160px;
                    max-height: 60px;
                }                
            }

        }

        .footer_block {
            text-align: center !important;
        }
            
    }

    .content_layout {
        background-color: #fff;
        min-height: 100vh;

        .main_content {
            margin: 30px 20px;
    
            .login_form__button {
                width: 100%;
            }
    
            .form__item {
                margin-bottom: 5px;
            }
    
            .main_page__carousel {
                
                .carousel__image {
                    width: 100%;
                    margin-right: auto;
                    margin-left: auto;
                    border: 20px solid #175fa4;                
                }
    
                .carousel__title {
                    color: #175fa4;                
                    text-align: center;
                }
            }
    
            .main_page__it_calc {
                .calc_price {
                    padding: 20px 0;
                    font-size: 1.2em;
                    border-top: 1px solid #175fa4;                
                }

                .calc_services {
                    padding: 20px 0;
                    border-top: 1px solid #175fa4;
                    @media (min-width: 992px) {
                        border-right: 1px solid #175fa4;
                    }
                }
    
                .calc_contact_form {
                    padding: 20px 0;
                    border-top: 1px solid #175fa4;
                }
            }

            .service_image { 
                margin: 20px 0; 
                border: 5px solid #175fa4;

                @media (min-width: 992px) {
                    width: 60%;
                }
                @media (min-width: 768px) and (max-width: 991px) {
                    width: 75%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
            
        }
        
        
    }

}
.drawer {
    .logo__container {
        text-align: center;
        
        .logo__icon {
            margin: 10px 0;
            width: 160px;
            height: 66px;                
        }
    }
    
    .company_address {
        line-height: 20px !important;
        margin: 20px 0 0 0;
    }
    
    .company_phones {
        line-height: 20px !important;
        margin: 20px 0 0 0;
    }

    .header_menu {
        margin: 20px 0 0 0;
    }
}

.editor-wrapper {
    border: solid #b3b3b3 1px;
    border-bottom-left-radius: 5px;  
    border-bottom-right-radius: 5px;
}

.ck-editor__editable {
  min-height: 500px !important;
  max-height: 500px !important;
}

.article_container {
    .center-align {
        text-align: center;
        
        .responsive-img {
            max-width: 100%;
            margin: 10px 0;
        }
    }

    .video-container {
        position:relative;
        padding: 0 0 56.25% 0;
        height:0;
        overflow:hidden;
        margin: 10px 0;
    }

    .video-container iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }

    .responsive-tbl {
        width: auto;
        min-width: 100%;
        table-layout: auto;
        margin: 10px 0;
    }
}

















